.required-field {
  position: relative;

  &::after {
    content: "*";
    position: absolute;
    top: 0;
    right: -8px;
    color: var(--color-danger);
  }
}

.summary-badge {
  width: 65px;
}

.results-preview-collapse-title {
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.overflowing-summary {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}